import request from '@/utils/request'
//远程管控
export function storeList (data) {
    return request({
        url: '/store/list',
        method: 'get',
        params: data
    })
}
export function storeBan (data) {
    return request({
        url: '/store/is_ban',
        method: 'post',
        data
    })
}
export function storeAdd (data) {
    return request({
        url: '/store/add',
        method: 'post',
        data
    })
}
export function storeDel (data) {
    return request({
        url: '/store/del',
        method: 'post',
        data
    })
}
export function storeInfo (data) {
    return request({
        url: '/store/info',
        method: 'get',
        params: data
    })
}

export function getMintain (data) {
    return request({
        url: '/operation/get_maintain',
        method: 'get',
        params: data
    })
}

export function maintainList (data) {
    return request({
        url: '/operation/maintain_list',
        method: 'get',
        params: data
    })
}
export function storeUpdate (data) {
    return request({
        url: '/store/update',
        method: 'post',
        data
    })
}
export function storeSetup (data) {
    return request({
        url: '/store/setup_oem',
        method: 'post',
        data
    })
}

export function sealSwitch (data) {
    return request({
        url: '/store/seals_witch',
        method: 'post',
        data
    })
}

export function storeGetin (data) {
    return request({
        url: '/store/get_into',
        method: 'post',
        data
    })
}
export function changeBan (data) {
    return request({
        url: '/store/is_ban',
        method: 'post',
        data
    })
}



export function storeSynchro(data) {
    return request({
        url: '/store/synchro',
        method: 'post',
        data
    })
}

export function storeUpdateScnum(data) {
    return request({
        url: '/store/update_scnum',
        method: 'post',
        data
    })
}

export function manysiginWitch(data) {
    return request({
        url: '/store/manysigin_witch',
        method: 'post',
        data
    })
}

export function authenticationWitch(data) {
    return request({
        url: '/store/authentication_witch',
        method: 'post',
        data
    })
}


export function addMaintain(data) {
    return request({
        url: '/operation/add_maintain',
        method: 'post',
        data
    })
}

export function editMaintain(data) {
    return request({
        url: '/operation/edit_maintain',
        method: 'post',
        data
    })
}


export function addRelease(data) {
    return request({
        url: '/operation/add_release',
        method: 'post',
        data
    })
}

export function delMaintain(data) {
    return request({
        url: '/operation/del_maintain',
        method: 'post',
        data
    })
}

export function editionSwitch(data) {
    return request({
        url: '/store/newedition_witch',
        method: 'post',
        data
    })
}
export function appletSwitch(data) {
    return request({
        url: '/store/applet_release_witch',
        method: 'post',
        data
    })
}

